<template>
  <div v-if="activeBooking" class="booking-details-summary">
    <div>
      <AppLabel
        label="ACCOMODATION"
        :label-right="activeBookingDetails.hotel.date"
      />
      <BookingHotelCardDetails
        :hotel="activeBookingDetails.hotel.data"
        :booking="activeBookingDetails.booking"
        info-label="Hotel reservation details"
        show-card
      />
    </div>
    <div>
      <AppLabel label="RESERVATION DETAILS" />
      <SettingsCards
        :data="activeBookingDetails.hotel.reservationDetails"
        small
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppLabel from '../../components-rf/AppLabel.vue'
import BookingHotelCardDetails from '../../components-rf/bookings/BookingHotelCardDetails.vue'
import SettingsCards from '../../components/common/SettingsCards.vue'

export default {
  name: 'BookingDetailsHotel',
  components: {
    AppLabel,
    SettingsCards,
    BookingHotelCardDetails,
  },
  computed: {
    ...mapState('BookingModule', ['activeBooking']),
    ...mapGetters('BookingModule', ['activeBookingDetails']),
  },
}
</script>

<style scoped lang="scss">
.booking-details-summary {
  display: grid;
  gap: 1rem;
  & > div {
    display: grid;
    gap: 0.5rem;
  }
}
</style>
