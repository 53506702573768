<template>
  <div
    :class="{ 'app-card-clickable': showCard }"
    class="hotel-card"
    tabindex="0"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  >
    <p class="hotel-card-name">{{ display.name }}</p>
    <div class="hotel-card-main">
      <AppThumbnailWithPlaceholder
        :src="display.imageUrl"
        placeholder="hotel"
      />
      <div class="hotel-card-text">
        <p>{{ display.address }}, {{ display.cityName }}</p>
        <p>Hotel PNR: {{ booking.hotelReservationCode }}</p>
      </div>
    </div>
    <template v-if="showInfo">
      <AppDashedLine />
      <AppCardInfo
        :label="infoLabel"
        :price="infoPrice"
        class="app-card-info"
      />
    </template>
  </div>
</template>

<script>
import AppCardInfo from '../AppCardInfo.vue'
import AppDashedLine from '../AppDashedLine.vue'
import AppThumbnailWithPlaceholder from '../AppThumbnailWithPlaceholder.vue'

export default {
  name: 'BookingHotelCardDetails',
  components: {
    AppCardInfo,
    AppDashedLine,
    AppThumbnailWithPlaceholder,
  },
  props: {
    booking: Object,
    hotel: Object,
    infoPrice: Number,
    infoLabel: String,
    showInfo: Boolean,
    showCard: Boolean,
  },
  computed: {
    display() {
      return {
        name: this.hotel.name,
        address: this.hotel.address,
        imageUrl: this.hotel.imageUrl,
        cityName: this.hotel.city.name,
        regionName: this.hotel.city.region.name,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.hotel-card {
  display: grid;
  gap: 1rem;
}
@media (hover: hover) {
  .hotel-card:hover .app-card-info {
    --box-shadow-height: 2px;
  }
}
.hotel-card-name {
  font-size: 16px;
  font-family: DDin;
  line-height: 1.3;
}
.hotel-card-main {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  line-height: 1.5;
}
.hotel-card-text {
  display: grid;
  line-height: 1.2;
  font-size: 15px;
  font-family: DDin;
}
</style>
